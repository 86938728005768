// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import jQuery from 'jquery'
import 'bootstrap';

window.$ = jQuery
window.jQuery = jQuery

require("../../../vendor/assets/javascripts/inputmask.min.js")

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

require("./components/items")
require("./components/users")

jQuery(document).ready(function ($) {
  var updateInputMask = function () {
    Inputmask({ "mask": "(999) 999-9999" }).mask($('.phone-field'));
  }

  updateInputMask()

  $('.remove-element-remote').bind('ajax:success', function () {
    $(this).closest('.remove-element-container').fadeOut();
  });

  const callbackOnFileStackUploadFinished = (field) => (data) => {
    const imageField = $(field).closest('.filestack-container').find('.filestack-image')
    imageField.attr('src', data.url)
  }

  window.callbackOnFileStackUploadFinished = callbackOnFileStackUploadFinished
})


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
