$(document).on('ready turbolinks:load', function () {
  const defaultInputBackground = 'rgba(0, 0, 0, 0)'

  $("form").submit(function (e) {
    let $form = $(e.currentTarget);
    if ($form.data('js-validate')) {
      let validationFailed = false;
      $form.find(':input').each(function () {
        let $input = $(this);
        if (($input.data('validations')) && ($input.data('validations').required)) {
          validationFailed = validationFailed || $(this).val().length === 0
          validateRequiredInput($input);
        }
      })
      if (validationFailed) {
        e.preventDefault();
        return false;
      }
    }
  });

  $(".MuiOutlinedInput-input").each(function (i, el) {
    $input = $(el)
    let style = window.getComputedStyle(el)
    let inputAutofilledByBrowser = style && style.backgroundColor !== defaultInputBackground
    if (($input.val().length > 0) || inputAutofilledByBrowser) { animateField($input) }
  });

  function animateField(field, focuse = false) {
    $formControl = field.parents('.MuiFormControl-root')
    $label = $formControl.find('label')
    $label.addClass('MuiInputLabel-shrink MuiFormLabel-filled Mui-focused')
    $label.attr('data-shrink', true)
    if (focuse) { $formControl.find('.MuiInputBase-root').addClass('Mui-focused') }
    $formControl.find('legend').addClass('jss231')
  }

  function loseFocusOnField(field) {
    if (field.val().length === 0) {
      $formControl = field.parents('.MuiFormControl-root')
      $label = $formControl.find('label')
      $label.removeClass('MuiInputLabel-shrink MuiFormLabel-filled Mui-focused')
      $label.attr('data-shrink', false)
      $formControl.find('.MuiInputBase-root').removeClass('Mui-focused')
      $formControl.find('legend').removeClass('jss231')
    } else {
      $formControl.find('.MuiInputBase-root').removeClass('Mui-focused')
      $label.removeClass('Mui-focused')
    }
  }

  function validateRequiredInput(input) {
    let $inputWrapper = input.parents('.MuiFormControl-root')
    if (!$inputWrapper.parents('form').data('js-validate')) { return; }

    if (input.val().length === 0) {
      $inputWrapper.find('label').addClass('Mui-error')
      $inputWrapper.find('label').attr('aria-invalid', true)
      $inputWrapper.find('.MuiInputBase-root').addClass('Mui-error')
      $inputWrapper.find('.validation-error-text').removeClass('hidden')
    } else {
      $inputWrapper.find('label').removeClass('Mui-error')
      $inputWrapper.find('label').attr('aria-invalid', false)
      $inputWrapper.find('.MuiInputBase-root').removeClass('Mui-error')
      $inputWrapper.find('.validation-error-text').addClass('hidden')
    }
  }

  $(".MuiOutlinedInput-input").on('animationstart', function (e) {
    var $input = $(e.currentTarget)
    switch (e.originalEvent.animationName) {
      case 'mui-auto-fill':
        animateField($input)
        break;
      case 'mui-auto-fill-cancel':
        loseFocusOnField($input)
    }
  });

  $(".MuiOutlinedInput-input").on('input', function () {
    validateRequiredInput($(this));
  });

  $(".MuiOutlinedInput-input").on('focusin input', function (e) {
    animateField($(e.currentTarget), true)
  });

  $(".MuiOutlinedInput-input").on('focusout', function (e) {
    loseFocusOnField($(e.currentTarget))
  });
})
