$(document).on('ready turbolinks:load', function () {
  const makeDelay = (timerId, ms) => (callback) => {
    clearTimeout(window[timerId] || 0)
    window[timerId] = setTimeout(callback, ms)
  }

  const submitForm = (parentForm) => {
    parentForm.submit();
    $('.loader-overflow').addClass('show');
  }

  $('.form-get-remote').on('submit', (event) => {
    const values = $(event.target).serialize()

    const newUrl = window.location.pathname + `?${values}`

    history.pushState({}, null, newUrl);
  })

  $('.submit-select').change((event) => {
    const parentForm = $(event.target).closest("form");

    submitForm(parentForm);
  })

  $('.submit-input').on('keyup', (event) => {
    const parentForm = $(event.target).closest("form");
    const timerId = $(event.target).attr('id')

    makeDelay(timerId, 300)(() => submitForm(parentForm))

  })
})
